/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

console.log('Hello World from Webpacker')

var Trix  = require("trix")
// require("trix")
require("@rails/actiontext")

Trix.config.textAttributes.sup = { tagName: "sup", inheritable: true }
Trix.config.textAttributes.sub = { tagName: "sub", inheritable: true }

addEventListener("trix-initialize", function(event) {
	var element = event.target
  var editor = element.editor
  var toolbarElement = element.toolbarElement
  var groupElement = toolbarElement.querySelector(".trix-button-group.trix-button-group--text-tools")
	
	groupElement.insertAdjacentHTML("beforeend", '<button class="trix-button px-2" type="button" data-trix-attribute="sup">X<sup>2</sup></button>')
  groupElement.insertAdjacentHTML("beforeend",'<button class="trix-button px-2" type="button" data-trix-attribute="sub">X<sub>2</sub></button>')
  
  var selectedAttributes = new Set
  function updateSelectedAttributes() {
    selectedAttributes = new Set
    
    var selectedRange = editor.getSelectedRange()
    if (selectedRange[0] === selectedRange[1]) { selectedRange[1]++ }
    
    var selectedPieces = editor.getDocument().getDocumentAtRange(selectedRange).getPieces()
    selectedPieces.forEach(function(piece) {
    	Object.keys(piece.getAttributes()).forEach(function(attribute) {
      	selectedAttributes.add(attribute)
      })
    })
  }
  
  function enforceExclusiveAttributes() {
    if (editor.attributeIsActive("sup") && selectedAttributes.has("sub")) {
    	editor.deactivateAttribute("sub")
      updateSelectedAttributes()
    } else if (editor.attributeIsActive("sub") && selectedAttributes.has("sup")) {
    	editor.deactivateAttribute("sup")
      updateSelectedAttributes()
    }
  }
  
	updateSelectedAttributes()
  element.addEventListener("trix-selection-change", updateSelectedAttributes) 
  element.addEventListener("trix-change", enforceExclusiveAttributes)
})